<template>
    <div class="main">
        <Title > 公司简介</Title>
        <Pgp :indent = "'2rem'">
          堃铭是国内领先的智慧装备研发与制造企业，其承接集团在物证与鉴定领域的长期积累，通过为目标领域引入先进、跨界的技术，提供针对业务和任务的智慧装备解决方案，从而践行集团“智慧物证·可信鉴定”理念，为业务提效、为客户赋能。

        </Pgp>
        <Pgp :indent = "'2rem'">
          堃铭的目标领域主要包括：禁毒智慧装备、物证鉴定智慧装备、环境执法智慧装备、海洋研究智慧装备、疾病防控智慧装备、药物研究智慧装备、生化战争智慧装备等。目前堃铭已打造出一支实践经验丰富的团队，拥有三十几名博士、硕士及高级职称的专业人员；自主研发的多类产品已申报并获批多项国内、国际发明专利（主要包含美国、日本、欧盟）与实用新型专利。


        </Pgp>
        <ImagePreview class="col8 wow slideInRight hvr-backward" :src="img1" :srcList="imgList1"></ImagePreview>
        <Title ><pre style="font-family:initial;">自主创新 智慧物联</pre></Title>
        <Pgp :indent = "'2rem'">
          立足鉴定，软硬结合；产研一体，优势独具。通过综合鉴定服务与配套软硬件产品的结合，历思在多个领域建立了独特的竞争优势。例如，在服务禁毒上，污水验毒+毛发检验的综合技术能力，结合自主研发的智慧水质采样设备、毒品新型检测平台、禁毒赋能系统等，可为客户提供理想的完整解决方案。
        </Pgp>
        <div class="img_box">
            <ImagePreview v-for="item in data"
            style="margin-bottom:2rem;" :key="item.title" class="col3 hvr-forward" :src="require('../../assets/Domain/KM'+item.img)" :srcList="[require('../../assets/Domain/KM'+item.img)]">{{item.title}}</ImagePreview>
        
        </div>
        <Pgp class="wow slideInLeft">通过在污水处理厂入口、污水汇集点等处大量部署集群网络化在线智慧水质采样和预警设备，结合车载机动布点和移动监测，实现在线实时检测预警、移动定点排查和实验室精准检测，形成毒情监测大数据，在系统平台上自动展示毒情态势及分析报告，打造先进的毒情分析情报能力，可为建立更科学、合理的禁毒成效评估与绩效考核体系提供依据。同时，污水毒情数据还可与毛发等生物检材检测大数据融合联动，实现精准导侦和更高层次的禁毒大数据运用。</Pgp>
        <ImagePreview class="col8 wow slideInRight hvr-backward" :src="img3" :srcList="imgList3"></ImagePreview> 


        <!-- <div class="box">
            <div class="left wow slideInLeft">
                <Pgp >
                    厦门堃铭生物技术有限公司（以下简称堃铭），为厦门历思科技服务有限公司（以下简称历思）全资子公司。
                </Pgp>
                <Pgp :indent = "'2rem'">
                    堃铭成立的目的是为了历思在生物技术领域能更专业、更系统地发展，因此承接历思在生物科技方面的主要业务与技术。目前规划方向为五个领域：
                </Pgp>
                <Pgp :indent = "'2rem'">
                     1、禁毒及司法鉴定领域分析仪器的研发与生产，以及配套的上下游物证技术产品的研发、生产、销售。
                </Pgp>
                <Pgp :indent = "'2rem'">
                    2、海洋生物检验设备及在线监控系统设施的研发生产销售，含系统工程建设。
                </Pgp>
                <Pgp :indent = "'2rem'">
                    3、流行病的在线预警系统研发、生产、销售与施工。
                </Pgp>
                <Pgp :indent = "'2rem'">
                    4、食品快速检测设备、在线检测设备、高端实验分析设备及预警系统，以及食品物证相关取证设备的研发、生产、销售；食品物证证据化培训等。
                </Pgp>
                <Pgp :indent = "'2rem'">
                    5、环境快速检测设备、在线检测设备、高端实验分析设备及预警系统，以及环境物证的相关取证设备的研发、生产、销售；环境物证证据化培训等。
                </Pgp>
            </div>
            <ImagePreview class="img_s  wow slideInRight" :src="src1" :srcList="[src1]"></ImagePreview>
        </div>
        <Title >产品展示</Title>
        
        <More></More> -->
        
    </div>
</template>
<script>
import src1 from '../../assets/Domain/KM/1.png';
import img1 from "@/assets/Goin/innovate/1.png"
import img3 from "@/assets/Goin/innovate/3.jpg"
export default {
    data(){
        return {
            src1:src1,
            img1:img1,
            img3:img3,
            imgList1:[img1],
            imgList3:[img3],
        }
    },
    computed:{
        data(){
            return this.$store.state.kmProductData;
        }
    },
}
</script>
<style src="../../layout.css" scoped></style>
<style scoped>
.main{
    width:80vw;
    margin:0 auto;
    margin-bottom:3rem;
    max-width:1600px;
}
.img_s{
    min-width:300px;
    width:25vw;
    max-width:350px;
    margin:0 auto;
    max-height: 300px;
}
.img_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.img8{
  width:100%;
  height:100%
}
.img{
    min-width:300px;
    width:30vw;
    max-width:400px;
    margin:0 auto;
    height: 22vw;
    max-height: 281px;
    margin-bottom:2rem;
    min-height: 200px;
}
.box{
    margin:3rem 0;
    display:flex;
    justify-content:space-around;
    flex-wrap:wrap;
}
.left{
    width:60%;
    min-width:300px;
    padding-right:20px;
}
/deep/.left .p{
    margin:0;
    font-size:
}
@media screen and ( max-width:1200px){
    .left{
        width:100%;
        margin-bottom:2rem;
    }
    
}
</style>